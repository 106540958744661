// @import "../../assets/styles/color-schema.scss";

// .btn {
//   display: inline-block;
//   font-family: $btn-font-family;
//   font-weight: $btn-font-weight;
//   text-align: center;
//   vertical-align: middle;
//   user-select: none;
//   background-color: transparent;
//   border: $btn-border-width solid transparent;

//   @include hover {
//     text-decoration: none;
//   }

//   &:focus,
//   &.focus {
//     outline: 0;
//   }
// }

// .btn-action {
//   margin: 0 5px;
// }

// .btn-green {
//   background: theme-color("success");
//   color: theme-color("primary");
//   border: none;
//   &:hover {
//     color: theme-color("primary");
//   }
// }
// .btn-red {
//   background: theme-color("danger");
//   color: theme-color("primary");
//   border: none;
//   &:hover {
//     color: theme-color("primary");
//   }
// }

.custom-modal{
    margin: 0 24px;
    overflow-y: scroll;
}
.pagination>.active>a {
    background-color: #235687;
    border-color: #235687;
}
.pagination>li>a, .pagination>li>span {
    color: #235687;
}
.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
    outline: 0;
    // border-color: inherit;
}
