.snackbar-block {

    .MuiPaper-root {

        display: flex;
        align-items: center;
        justify-content: center;

        * {
            color: #ffffff;
        }
    }
}
