@import "../../../../assets/styles/color-schema.scss";

.profile-preferences {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
        max-width: 950px;
    }

    h1 {
        position: relative;
        width: 100%;
        margin: 32px 0 5px;

        .icon-right-arrow{
            position: absolute;
            left: -17px;
            top: 7px;
            font-size: 14px;
            transform: rotate(0.5turn);
        }
    }

    h3 {
        margin-top: 32px;
    }

    p {
        padding: 0 20px;
        font-size: 14px;
        text-align: center;
    }

    > p:last-of-type {
        margin-top: 32px;
    } 

    > img {
        width: calc(100% - 40px);
    }

    > .preferences-slider {
        width: calc(100% - 40px);

        .slider-label {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-family: FuturaBook;
                font-size: 14px;
                font-weight: 300;  
                letter-spacing: -0.3px;
            }
        }
    }

    .MuiSlider-rail {
        height: 22px;
        opacity: 1;
        background-color: #ffffff;
        border-radius: 24px;
        border: 2px solid $theme-blue;
    }

    .MuiSlider-track {
        height: 26px;
        margin-left: -12px;
    }

    .MuiSlider-thumb {
        width: 26px;
        height: 26px;
        margin-top: 0;
        margin-left: -24px;
    }

    .action-block {
        button {
            padding: 6px;
            border-radius: 24px;
            line-height: 1;
        }
    }

    .content-block {
        h3 {
            margin-top: 0;
        }
    }

    .btn-link {
        margin: 33px 0 68px;

        button {
            margin: 0;
        }
    }
}

@media screen and (max-width: 768px){
    .profile-preferences {
        > * {
            max-width: calc(100% - 76px);
        }
    }
}