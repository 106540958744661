@import "../../../../assets/styles/color-schema.scss";

.profile-tab-wrapper {
    padding-top: 183px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1024px) { 
          padding-top: 164px;  
     }

    // .MuiDrawer-paper {
    //     position: relative;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     width: 100%;
    //     height: 90px;
    // }

    // h1 {
    //     font-size: 26px;
    //     position: relative;
    //     width: 100%;

    //     .icon-upload {
    //         position: absolute;
    //         left: 15px;
    //         font-size: 23px;
    //     }
        
    //     .icon-settings {
    //         position: absolute;
    //         right: 15px;
    //         font-size: 23px;
    //     }
    // }

    // > h1 {
    //     margin-top: 70px;
    // }

    // .icon-user {
    //     font-size: 149px;
    //     margin-bottom: 28px;
    // }

    // .tab-content-wrapper {

    //     h1 {
    //         font-size: 34px;
    //     }

    //     .MuiBox-root {
    //         display: flex;
    //         align-items: center;
    //         flex-direction: column;

    //         p {
    //             font-family: FuturaBook;
    //             font-size: 16px;
    //             text-align: center;
    //         }
    //     }
    // }

    // .MuiTabs-root {
        
    //     width: 100%;

    //     .MuiTab-root {
    //         width: 50%;
    //         max-width: 100%;
    //         text-transform: none;
    //         border-bottom: 4px solid #f0f0f0;
    //         opacity: 1;

    //         .MuiTab-wrapper {
    //             font-size: 18px;
    //             font-weight: bold;
    //             letter-spacing: -0.39px;          
    //             font-stretch: normal;
    //             font-style: normal;            
    //             line-height: normal;
    //         }
    //     }
    // }

    // .MuiTabs-indicator {
    //     background-color: $theme-blue;
    //     height: 4px;
    // }

    // .btn-link {
    //     margin-top: 17px;
    // }
}

// .share-drawer {

//     .MuiDrawer-paper {
//         border-bottom: none;
//         border-top-left-radius: 23px;
//         border-top-right-radius: 23px;
//         height: calc(50% - 144px);
//         max-height: calc(50% - 144px);
//         padding: 72px 0;

//         .drawer-header {
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: center;
//             position: fixed;
//             bottom: calc(50% - 72px);
//             width: 100%;
//             height: 72px;
//             border-top-left-radius: 23px;
//             border-top-right-radius: 23px;
//             border-bottom: 1px solid $theme-light-grey3;
//             background-color: #ffffff;
//             z-index: 1;

//             [class^="icon-"] {
//                 position: absolute;
//                 top: 35px;
//                 left: 25px;
//                 font-size: 12px;
//             }

//             .header-line {
//                 width: 70px;
//                 height: 0px;
//                 border: 2px solid $theme-light-grey3;
//                 border-radius: 4px;
//                 margin-top: 14px;
//             }
//         }

//         .share-block {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             width: 100%;
//             background-color: #ffffff;
//             margin-top: 20px;

//             button {
//                 height: 49px;
//                 width: 250px;
//                 margin-bottom: 23px;
//                 border-radius: 35px;
//             }
            
//         }
//     }
// }


// @media screen and (max-width: 768px){
//     .profile-tab-wrapper {
        
//         > h1 {
//             margin: 32px 0 16px;
//         }

//         .icon-user {
//             font-size: 52px;
//             margin-bottom: 16px;
//         }

//         .tab-content-wrapper {

//             h1 {
//                 margin: 20px 0 0 0;
//                 font-size: 24px;
//             }

//             .MuiBox-root {
    
//                 > h1 {
//                     margin: 10px;
//                 }
//             }
//         }
//     }
// }
