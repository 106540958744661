@import "../../../../assets/styles/color-schema.scss";

.google-map-wrapper {
    width: 100%;
    height: calc(100% - 64px);

    #venue-google-map {
        width: 100%;
        height: 100%;
    }

    .icon-placeholder {
        color: $theme-blue;
        font-size: 81px;

        &.active {
            color: $theme-purple;
        }

    }
}

@media screen and (max-width: 768px){
    .google-map-wrapper {
        .icon-placeholder {
            font-size: 41px;
        }
    }
}