
@font-face {
    font-family: 'Noyh';
    src: url('/assets/fonts/Noyh-Thin.eot');
    src: local('Noyh Thin'), local('Noyh-Thin'),
        url('/assets/fonts/Noyh-Thin.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Noyh-Thin.woff2') format('woff2'),
        url('/assets/fonts/Noyh-Thin.woff') format('woff'),
        url('/assets/fonts/Noyh-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noyh';
    src: url('/assets/fonts/Noyh-Light.eot');
    src: local('Noyh Light'), local('Noyh-Light'),
        url('/assets/fonts/Noyh-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Noyh-Light.woff2') format('woff2'),
        url('/assets/fonts/Noyh-Light.woff') format('woff'),
        url('/assets/fonts/Noyh-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noyh';
    src: url('/assets/fonts/Noyh-Regular.eot');
    src: local('Noyh'), local('Noyh-Regular'),
        url('/assets/fonts/Noyh-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Noyh-Regular.woff2') format('woff2'),
        url('/assets/fonts/Noyh-Regular.woff') format('woff'),
        url('/assets/fonts/Noyh-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noyh';
    src: url('/assets/fonts/Noyh-Medium.eot');
    src: local('Noyh Mediium'), local('Noyh-Medium'),
        url('/assets/fonts/Noyh-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Noyh-Medium.woff2') format('woff2'),
        url('/assets/fonts/Noyh-Medium.woff') format('woff'),
        url('/assets/fonts/Noyh-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noyh';
    src: url('/assets/fonts/Noyh-Bold.eot');
    src: local('Noyh Bold'), local('Noyh-Bold'),
        url('/assets/fonts/Noyh-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Noyh-Bold.woff2') format('woff2'),
        url('/assets/fonts/Noyh-Bold.woff') format('woff'),
        url('/assets/fonts/Noyh-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}








