@import "./assets/styles/color-schema.scss";
@import "./assets/styles/fonts";
@import './assets/styles/variables';
@import "./assets/styles/mixins";
@import "./assets/styles/utilities";

@font-face {
    font-family: 'NoyhThin';
    src: local('NoyhThin'), url(./assets/fonts/NoyhThin.otf) format('truetype');
}

@font-face {
    font-family: 'NoyhBold';
    src: local('NoyhBold'), url(./assets/fonts/NoyhBold.otf) format('truetype');
}

@font-face {
    font-family: 'FuturaBook';
    src: local('FuturaBook'), url(./assets/fonts/FuturaBook.ttf) format('truetype');
}

html, body, div, span, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, cite, code, del, dfn, em, font, img, ins, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    vertical-align:baseline;
    background:transparent;
    
}

:focus {
    outline: 0;
}

ol, ul {
    list-style-position: inside;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

html, body, #root { 
    height: 100%;
}
button {
    &:focus {
        outline: none !important;
    }
    
}
body {
    font-family: 'Noyh';
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Noyh';
}

// * {
//     font-family: NoyhBold;
//     font-size: 16px;
//     color: $theme-text-color;
//     // letter-spacing: -0.3px;
// }

// h1 {
//     font-size: 50px;
//     margin: 20px;
//     text-align: center;
//     letter-spacing: -0.51px;
// }

// h3 {
//     font-size: 34px;
//     margin: 16px;
//     text-align: center;
// }

// h5 {
//     font-size: 18px;
// }

// p {
//     font-family: FuturaBook;
//     font-size: 14px;
//     font-weight: 300;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 2;
//     letter-spacing: -0.3px;
//     text-align: center;
// }

// .content {
//     h1 {
//         margin: 60px 0 25px;
//     }
// }

button.btn-root {
    font-size: 16px;
    font-family: 'Noyh';
}
// button {
//     &.MuiButton-root {
//         border: solid 2px $theme-blue;
//         border-radius: 48px;
//         font-size: 18px;
//         font-weight: bold;
//         padding: 12px 24px;
//         text-transform: none;
//         background-color: $theme-blue;
//         color: $theme-text-light-color;

//         .MuiButton-label {
//             font-size: 18px;
//             color: $theme-text-light-color;

//             [class^='icon-'] {
//                 font-size: 16px;
//                 margin-left: 10px;
//                 color: $theme-text-light-color;
//             }
//         }

//         &:hover {
//             background-color: $theme-text-light-color;

//             .MuiButton-label {
//                 color: $theme-blue;

//                 [class^='icon-'] {
//                     color: $theme-blue;
//                 }
//             }
//         }
//     }

//     &.MuiButton-outlined {
//         border: solid 2px $theme-blue;
//         color: $theme-blue;
//         background-color: $theme-text-light-color;

//         .MuiButton-label {
//             color: $theme-blue;
//         }

//         &.active {
//             background-color: $theme-blue;
    
//             .MuiButton-label {
//                 color: $theme-text-light-color;
//             }
//         }

//         &:hover {
//             background-color: $theme-blue;

//             .MuiButton-label {
//                 color: $theme-text-light-color;
//             }
//         }
//     }

//     &.btn-light {
//         border: solid 2px $theme-text-light-color;
//         color: $theme-blue;
//         background-color: $theme-text-light-color;

//         .MuiButton-label {
//             font-size: 18px;
//             color: $theme-blue;

//             [class^='icon-'] {
//                 color: $theme-blue;
//             }
//         }

//         &:hover {
//             background-color: $theme-blue;

//             .MuiButton-label {
//                 color: $theme-text-light-color;

//                 [class^='icon-'] {
//                     color: $theme-text-light-color;
//                 }
//             }
//         }
//     }

//     &.btn-gradient {
//         min-width: 240px;
//         border-radius: 35px;
//         margin: 20px auto;
//         text-transform: none;
//         padding: 22px;
//         font-weight: bold;
//         border: none;
//         border-radius: 44px;
//         background-image: linear-gradient(to right, $theme-purple 9%, $theme-orange);
//         color: $theme-text-light-color;

//         &:hover {
//             background-image: linear-gradient(to right, $theme-orange 9%, $theme-purple);
//         }

//         .MuiButton-label {
//             font-size: 34px;
//             line-height: 34px;
//             color: $theme-text-light-color !important;

//             span {
//                 color: $theme-text-light-color !important;
//             }
//         }
//     }

//     &.btn-sm {
//         padding: 8px 16px;
//         border-radius: 22px;

//         .MuiButton-label {
//             font-family: FuturaBook;
//             font-size: 16px;
//         }
//     }

//     &.google-btn {
//         justify-content: center;
//         position: relative;
//         height: 49px;
//         width: 250px;
//         margin-bottom: 23px;
//         border-radius: 35px !important;
//         box-shadow: none !important;
//         border: 1px solid $theme-blue !important;

//         > div {
//             border-radius: 35px !important;
//             background: transparent !important;
//             position: absolute;
//             left: 14px;
//             top: 14px;
//             padding: 0 !important;
//             margin: 0 !important;

//             svg {
//                 height: 25px;
//                 width: 25px;
//                 font-size: 25px;

//                 > * {
//                     font-size: 25px;
//                 }
//             }
//         }

//         > span {
//             font-size: 18px;
//             font-weight: bold;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: -0.39px;
//             text-align: center;
//         }
//     }
// }

.error {
    color: red;
}

.content-card {
    height: auto;
    margin: 10px;
    padding: 10px;
    border: 3px solid;
    border-radius: 33px;

    &.MuiListItem-root {
        width: auto;
    }
}

.MuiCheckbox-root,
.MuiRadio-root{
    .MuiSvgIcon-root{
        font-size: 24px;
    }
}

.top-header-spacing{
    padding-top: 183px;
     @media screen and (max-width: 1024px) { 
          padding-top: 164px;  
     }
}

// form {
//     .MuiInputLabel-formControl {
//         top: -60px;
//         font-weight: bold;
//         font-size: 34px;
//     }
// }

.card-block {
    box-shadow: none !important;
    background-color: $theme-light-grey2 !important;
    border-radius: 17px !important;
}

 .ss-yk16xz-control{
    min-height: 58px !important;
}

.table {
    thead {
        th {
            white-space: nowrap;
            border-bottom-width: 1px !important;
        }
    }
    tbody {
        td {
            vertical-align: middle !important;
        }
    }
}


@media screen and (max-width: 768px){
    // * {
    //     font-family: NoyhBold;
    //     font-size: 16px;
    //     color: $theme-text-color;
    // }
    
    // h1 {
    //     font-size: 24px;
    //     margin: 20px;
    //     text-align: center;
    // }
    
    // h3 {
    //     font-size: 18px;
    //     margin: 16px;
    //     text-align: center;
    // }

    // form {
    //     .MuiInputLabel-formControl {
    //         top: -50px;
    //         font-size: 18px !important;
    //     }

    //     .MuiInputBase-root {
    //         height: 41px;
    //     }

    //     p {
    //         font-size: 14px !important;
    //     }
    // }

    // button {
    //     &.btn-gradient {
    //         height: 49px;
    //         width: 250px;
    //         border-radius: 35px;
    //         padding: 12px;

    //         .MuiButton-label {
    //             font-size: 18px;
    //             line-height: normal;
    //         }
    //     }
    // }

    .content-card {
        border-radius: 18px;
    }
}
