@import "../../../../assets/styles/color-schema.scss";

.leaflet-map {
    height: 100%;
}

$venue-drawer-width: 646px;


.venue-drawer-wrapper {
    display: flex;
    width: 100%;
    height: 100%;

    .MuiDrawer-paper {
        position: relative;
        display: flex;
        align-items: center;
        width: $venue-drawer-width;
        background-color: transparent;
    }

    .venue-sidebar {
        position: relative;
        width: $venue-drawer-width;
        height: 100%;
        
        p {
            font-family: FuturaBook;
            text-align: center;
            margin: 22px 0;
        }

        .action-block {
            width: calc( 100% - 48px);
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 50px;
        
            .MuiButton-root{
                padding: 10px 12px;
                .MuiButton-label {
                    font-size: 24px;
                    line-height: 32px;
        
                    > span {
                        color: $theme-blue;
                        font-size: 18px;
                        margin-top: -7px;
                    }
        
                    &:hover {
                        > span {
                            color: #ffffff;
                        }
                    }
                }
        
                &.active {
                    .MuiButton-label {
                        > span {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .venue-drawer-wrapper {
        position: fixed;
        flex-direction: column;

        .MuiDrawer-paper {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 90px;
            /* background-color: #ffffff; */
            border-bottom: none;
        }
        
        .venue-mobile-map-header {
            position: absolute;
            width: 100%;
            height: 90px;

            .search-box {

                width: 100%;

                .venue-search {
                    height: 48px;
                    width: calc(100% - 24px);
                    margin: 28px 12px 11px;
                    padding: 10px 25px;
                    background-color: #ffffff;
                    border-radius: 30px;
                    box-shadow: 1px 3px 8px 1px;

                    .icon-filter {
                        cursor: pointer;
                    }
                }
            }
        }

        .leaflet-map {
            height: 100%;
            width: 100%;
        }

        .action-block {

            height: 100%;
            width: 100%;
            display: flex;
                align-items: center;
                justify-content: space-around;

            button {
                padding: 12px;
                border-radius: 24px;
                border-width: 1px;
                line-height: 1;

                &.MuiButton-root .MuiButton-label {
                    font-family: FuturaBook;
                    font-size: 14px;
                    font-weight: 300;
                    
                    [class^="icon-"] {
                        color: $theme-blue;
                    }
                }
            }
        }
    }
}
