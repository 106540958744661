@import "../../../../assets/styles/color-schema.scss";

.tech-feed-list {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .feed-list-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 11px 16px 4px;
        width: 700px;
        position: relative;

        > a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        .feed-description {
            h3 {
                margin: 10px 20px 0 0;
                text-align: left;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.96;
                letter-spacing: -0.51px;
            }

            .pure-link {
                margin-top: 5px;
                display: inline-block;
                font-family: FuturaBook;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.3px;
            }
        }

        .image-box {
            width: 270px;
            height: 270px;
            border-radius: 33px;
            overflow: hidden;

            img {
                width: auto;
                height: 100%;

                
            }
        }

        &:nth-of-type(2) {
            .image-box {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .feed-list-item:nth-of-type(even) .pure-link {
        color: $theme-purple;
    }

    .feed-list-item:nth-of-type(odd) .pure-link {
        color: $theme-orange;
    }
}

@media screen and (max-width: 768px){
    .tech-feed-list {
        .feed-list-item {
            width: auto;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            border-width: 1px;
            padding: 8px;

            .image-box {
                width: 135px;
                height: 135px;
                min-width: 135px;
                border-radius: 16px;
            }

            .feed-description {
                h3 {
                    font-size: 24px;
                }
            }
        }
    }
}