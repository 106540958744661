@import "../../../../assets/styles/color-schema.scss";

.wellness-for-me {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 26px;
        position: relative;
        width: 100%;

        .icon-settings {
            position: absolute;
            right: 23px;
            font-size: 23px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            margin: 28px 0;
            line-height: 1.5;
            letter-spacing: -0.3px;
            color: $theme-dark-grey;

            a {
                font-size: 18px;
            }
        }
    }
}