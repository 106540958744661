@import "../../../../assets/styles/color-schema.scss";
.quiz-question {
    .btn-ans{
        width: 48px;
        height: 48px;
        min-width: inherit;
        padding: 0;
        &.btn-ans-secondary{
            border-color: $secondary-main;
            &.active {
                background-color: $secondary-main;
            }
        }
        &.btn-ans-warning{
            border-color: $warning-main;
            &.active {
                background-color: $warning-main;
            }
        }
    }
}
// .quiz-container {
//     height: 100%;
//     align-content: start !important;


//     .wellness-quiz-consciousness {

//         height: calc(100% - 75px);

//         .quiz-stepper {
//             width: 100%;
//             height: 32px;
//             background: #fff;
//             position: fixed;
//             z-index: 999;


//             .MuiMobileStepper-progress {
//                 position: absolute;
//                 top: 34px !important;
//                 width: 100%;
//                 background-color: $theme-light-grey3;

//                 .MuiLinearProgress-bar {
//                     background-color: $theme-blue;
//                 }
//             }

//             > span {
//                 position: fixed;
//                 bottom: 34px !important;

//                 &.icon-left-arrow {
//                     left: 34px;
//                 }

//                 &.icon-right-arrow {
//                     right: 34px;
//                 }
//             }

//             > button {
//                 position: fixed;
//                 bottom: 18px;
//                 right: 14px;
//                 margin: 0;
//                 min-width: 158px;
//                 width: 158px;
//                 padding: 10px;
//             }

//             > a {
//                 position: fixed;
//                 bottom: 34px !important;
//                 left: 34px;
//             }
//         }

//         .quiz-screen {
//             width: 100%;
//             height: calc(100% - 48px);
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: start;
//             margin-top: 48px;

//             h1 {
//                 font-size: 40px;
//                 font-weight: normal;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: 0.93;
//                 letter-spacing: -0.86px;
//                 text-align: left;
//                 margin: 0 0 14px 0;
//             }

//             p {
//                 font-size: 14px;
//                 font-weight: 300;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: 1.57;
//                 letter-spacing: -0.5px;
//                 text-align: left;
//             }

//             > * {
//                 width: calc(100% - 80px);
//             }

//             .quiz-question {
//                 h3 {
//                     margin: 24px 0 28px;

//                     &:first-of-type {
//                         margin-top: 28px;
//                     }
//                 }
//             }
//         }

//     }
// }