@import "../../../../assets/styles/color-schema.scss";

.profile-atmospheres {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        position: relative;
        width: 100%;
        margin: 32px 0 5px;

        .icon-right-arrow{
            position: absolute;
            left: 17px;
            top: 0;
            font-size: 23px;
            transform: rotate(0.5turn);
        }
    }

    > h3 {
        margin: 32px 0 5px;
    }

    p {
        font-size: 14px;
    }

    .action-block {
        width: calc(100% - 44px);
        padding: 0 22px;

        button {

            height: 36px;
            width: 98px;

            &.active {
                background-color: $theme-blue;
        
                .MuiButton-label {
                    color: $theme-text-light-color;
                }
            }
        }
    }

    .content-block {

        margin: 0;

        h3 {
            margin: 38px 0 13px;
        }
    }

    .btn-gradient {
        margin: 55px 0 68px;
    }
}

@media screen and (max-width: 768px){
    .profile-atmospheres {
        .action-block {
            button {
                padding: 6px;
                border-radius: 24px;
                line-height: 1;
            }
        }
    }
}