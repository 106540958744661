@import "../../../../assets/styles/color-schema.scss";

$feed-drawer-height: 100px;

.feed-drawer-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    .MuiDrawer-paper {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        /* height: $feed-drawer-height; */
        background-color: #ffffff;
        border-color: $theme-blue;
    }
}

@media screen and (max-width: 768px){
    .feed-drawer-wrapper {
        h1 {
            margin: 25px 0;
            font-size: 18px;
        }
    }
}