@import "../../../../assets/styles/color-schema.scss";
.venue-form {
    padding-top: 183px;
    min-height: 100vh;
    @media screen and (max-width: 1024px) { 
          padding-top: 164px;  
     }
    .MuiContainer-root {
        min-height: calc(100vh - 183px);
        display: flex;
        flex-direction: column;
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .MuiMobileStepper-root{
        background: transparent;
        padding: 0;
        .MuiMobileStepper-progress {
            // position: absolute;
            // bottom: 0 !important;
            width: 100%;
            background-color: $theme-light-grey3;

            .MuiLinearProgress-bar {
                background-color: $theme-blue;
            }
        }
    }
    
}
// .venue-form {
//     height: calc(100% - 74px);
//     position: relative;
//     display: flex;
//     overflow: auto;

//     .MuiMobileStepper-root {
//         padding: 0;
    
//         .MuiMobileStepper-progress {
//             position: absolute;
//             bottom: 0 !important;
//             width: 100%;
//             background-color: $theme-light-grey3;

//             .MuiLinearProgress-bar {
//                 background-color: $theme-blue;
//             }
//         }
//     }

//     .form-block {
//         width: 100%;
//         margin-top: 70px;

//         .MuiFormGroup-root {
//             position: relative;

//             label {
//                 top: -15px;
//             }
//         }

//         .MuiFormLabel-root {
//             color: $theme-blue;
//         }

//         .MuiOutlinedInput-notchedOutline {
//             border-color: $theme-blue;
//         }


//         legend {
//             font-size: 24px;
//             font-weight: bold;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: -0.51px;

//             span {
//                 font-family: FuturaBook;
//                 font-size: 14px;
//                 font-weight: 300;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: 1.71;
//                 letter-spacing: -0.3px;
//             }
//         }

//         label {
//             top: -70px;
//             justify-content: space-between;
//             margin: 12px 0;

//             h5 {
//                 font-family: FuturaBook;
//                 font-size: 18px;
//                 font-weight: bold;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: -0.39px;
//             }

//             svg {
//                 path {
//                     font-size: 21px;
//                 }
//             }

//             .MuiFormControlLabel-label  {
//                 font-family: FuturaBook;
//                 font-size: 18px;
//             }
//         }
//     }

//     .venue-form-stepper {
//         width: 100%;
//         height: 34px;
//         background: #ffffff;
//         position: fixed;
//         z-index: 999;

//         > span {
//             position: fixed;
//             bottom: 96px !important;

//             &.icon-left-arrow {
//                 left: 34px;
//             }

//             &.icon-right-arrow {
//                 right: 34px;
//             }
//         }

//         > button {
//             position: fixed;
//             bottom: 74px;
//             right: 14px;
//             margin: 0;
//             min-width: 158px;
//             width: 158px;
//             padding: 10px;
//         }

//         > a {
//             position: absolute;
//             bottom: 34px !important;
//             left: 34px;
//         } 

//     }

//     > * {
//         width: calc(100% - 60px);
//         padding: 0 30px;
//     }

//     h1 {

//         font-size: 40px;
//         font-weight: normal;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: 0.93;
//         letter-spacing: -0.86px;
//         text-align: left;
//         margin: 63px 0 14px 0;
//     }

//     p {
//         font-size: 14px;
//         font-weight: 300;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: 1.57;
//         letter-spacing: -0.5px;
//         text-align: left;
//     }
// }