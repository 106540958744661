// @import "../../../../assets/styles/color-schema.scss";
.profile-settings {
    .MuiButton-label {
        justify-content: space-between;
    }
}

// .profile-settings {

//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     flex-direction: column;

//     > * {
//         width: calc(100% - 20px);
//         margin: 10px 0;
//     }

//     > h1 {
//         width: 100%;
//         margin: 35px 0 0 0;
//         position: relative;

//         .icon-close {
//             position: absolute;
//             left: 17px;
//             top: 0;
//             font-size: 23px;
//         }
//     }

//     button {
//         border-width: 1px;
//         border-radius: 7px;
//         margin: 5px 0;
//         padding: 5px 15px;

//         .MuiButton-label {
//             display: flex;
//             align-items: center;
//             justify-content: space-between;

//             > span.icon-right-arrow {
//                 color: $theme-blue;
//             }
//         }

//         &.logout-btn {
//             width: 100%;
//             border-color: $theme-purple;

//             .MuiButton-label {
//                 justify-content: center;
//                 color: $theme-purple;
//             }
//         }
//     }

//     button.btn-gradient.tribe-button {
//         width: 100%;
//         height: 91px;
//         border-radius: 14px;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         margin: 20px 0;

//         .tribe-button-left {
//             display: flex;
//             align-items: center;
//             justify-content: flex-start;

//             .icon-confetti {
//                 font-size: 49px;
//                 margin: 15px;
//             }
    
//             .MuiBox-root {
//                 > * {
//                     margin: 0;
//                     text-align: left;
//                     color: #ffffff;
//                 }
    
//                 p {
//                     font-family: FuturaBook;
//                     font-size: 14px; 
//                 }
//             }
//         }

//         .icon-right-arrow {
//             font-size: 14px;
//             margin-right: 8px;
//         }
        
//     }

//     .btn-group {
//         overflow: hidden;
//         border: 1px solid $theme-blue;
//         border-radius: 7px;

//         button {
//             border: none;
//             border-top: 1px solid;
//             border-radius: 0px;

//             &:first-of-type {
//                 border-top: none;
//             }

//             .MuiButton-label {
//                 span {
//                     color: $theme-blue;
//                 }
//             }
//         }

//         .MuiAccordion-root::before {
//             background-color: $theme-blue;
//         }
//     }

// }