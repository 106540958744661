@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?sij2ve');
  src:  url('../fonts/icomoon/icomoon.eot?sij2ve#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?sij2ve') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?sij2ve') format('woff'),
    url('../img/icomoon.svg?sij2ve#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-mumbli-logo-2:before {
    content: "\e915";
}
.icon-person:before {
    content: "\e912";
}
.icon-verified:before {
    content: "\e913";
}
.icon-certified:before {
    content: "\e914";
}
.icon-filter:before {
    content: "\e90f";
}
.icon-search:before {
    content: "\e910";
}
.icon-mumbli-logo:before {
    content: "\e90e";
}
.icon-close:before {
    content: "\e909";
}
.icon-check:before {
    content: "\e90a";
}
.icon-confetti:before {
    content: "\e90b";
}
.icon-left-arrow:before {
    content: "\e911";
}
.icon-right-arrow:before {
    content: "\e90c";
}
.icon-upload:before {
    content: "\e916";
}
.icon-settings:before {
    content: "\e90d";
}
.icon-bookmark:before {
    content: "\e905";
}
.icon-direction:before {
    content: "\e906";
}
.icon-padlock:before {
    content: "\e907";
}
.icon-edit:before {
    content: "\e908";
}
.icon-user:before {
    content: "\e900";
}
.icon-placeholder:before {
    content: "\e901";
}
.icon-peace-of-mind:before {
    content: "\e902";
}
.icon-logout:before {
    content: "\e903";
}
.icon-headphone:before {
    content: "\e904";
}