@import 'utilities/sizing';
@import 'utilities/spacing';
@import 'utilities/display';
@import 'utilities/position';


// font weight
@each $weight in $font-weights {
    .font-weight-#{$weight} {
        font-weight: $weight !important;
    }
}

// text transform
@each $transform in $transforms {
    .text-#{$transform} {
        text-transform: $transform !important;
    }
}

// text decoration
@each $decoration in $decorations {
    .text-#{$decoration} {
        text-decoration: $decoration !important;
    }
}

// text color
@each $color, $value in $colors {
    .text-#{$color} {
        color: $value !important;
    }
}

// white space
@each $prop in $white-space {
    .white-space-#{$prop} {
        white-space: $prop !important;
    }
}
// object fit
@each $object in $objects {
    .object-#{$object} {
        object-fit: $object !important;
    }
}

// z-index
@for $i from 0 through 10 {
    .z-index-#{$i*1} {
        z-index: $i * 1 !important;
    }
}

// overflow
@each $value in $overflows {
    .overflow-#{$value} {
        overflow: $value !important;
    }
    .overflow-x-#{$value} {
        overflow-x: $value !important;
    }
    .overflow-y-#{$value} {
        overflow-y: $value !important;
    }
}

.btn-gradient {
    background-image: linear-gradient(to right, $theme-purple 9%, $theme-orange) !important;
}

.rounded-pill{
    border-radius: 50rem !important;
}