@import "../../../../assets/styles/color-schema.scss";

.quiz-profile {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // height: calc(100% - 145px);
    // justify-content: start;
    // overflow: auto;


    // > * {
    //     max-width: 950px;
    // }

    // .page-header {
    //     width: 100%;
    //     position: relative;
    //     border-bottom: 1px solid $theme-light-grey3;

    //     h1 {
    //         margin: 33px 0 16px;
    //     }

    //     .icon-right-arrow{
    //         position: absolute;
    //         left: 25px;
    //         top: 40px;
    //         font-size: 14px;
    //         transform: rotate(0.5turn);
    //     }
    // }

    .card-block {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: start;
        // margin: 10px 15px;
        // padding: 20px;
        // overflow: initial;

        // h5 {
        //     margin: 5px 0;
        //     font-family: FuturaBook;
        //     font-size: 18px;
        //     font-weight: bold; /* 500; */
        //     font-stretch: normal;
        //     font-style: normal;
        //     line-height: normal;
        //     letter-spacing: -0.39px;
        // }

        // p {
        //     font-family: FuturaBook;
        //     font-size: 14px;
        //     font-weight: 300;
        //     font-stretch: normal;
        //     font-style: normal;
        //     line-height: 1.57;
        //     letter-spacing: -0.3px;
        // }

        // > a {
        //     text-decoration: underline;
        //     font-family: FuturaBook;
        //     font-size: 14px;
        //     font-weight: 300;
        //     font-stretch: normal;
        //     font-style: normal;
        //     line-height: 1.57;
        //     letter-spacing: -0.3px;
        // }

        .preferences-slider {
            width: 100%;
            margin: 12px 0;
    
            .slider-label {
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                span {
                    font-family: FuturaBook;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 500;  
                    letter-spacing: -0.3px;
                    color: $theme-grey2;
                }
            }

            .MuiSlider-root {
                padding: 10px 0;

                .MuiSlider-rail {
                    height: 11px;
                    opacity: 1;
                    background-color: $theme-light-grey3;
                    border-radius: 5px;
                }
            
                .MuiSlider-track {
                    display: none;
                    height: 10px;
                    margin-left: 0;
                }
            
                .MuiSlider-thumb {
                    display: none;
                    width: 15px;
                    height: 10px;
                    margin-top: 0;
                    margin-left: -10px;
                    border-radius: 5px;
                }

                
            }
            
        }

        &.consciousness-slider{
            > a {
                color: $theme-purple;
            }
        }

        &.importance-slider {
            > a {
                color: $theme-orange;
            }
        }

        &.active {
            &.consciousness-slider{
                .slider-label {
                    span {
                        color: $theme-purple;
                    }
                }
                .MuiSlider-root {
                    .MuiSlider-track {
                        display: block;
                        background-color: $theme-purple;
                    }

                    .MuiSlider-thumb  {
                        display: flex;
                        background-color: $theme-purple;
                    }
                }
            }

            &.importance-slider {

                .slider-label {
                    span {
                        color: $theme-orange;
                    }
                }

                .MuiSlider-root {

                    .MuiSlider-track {
                        display: block;
                        background-color: $theme-orange;
                    }

                    .MuiSlider-thumb  {
                        display: flex;
                        background-color: $theme-orange;
                    }
                }
            }
        }
    }
}
