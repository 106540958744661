@import "../../assets/styles/color-schema.scss";

$drawer-width: 268px;

.auth-layout-wrapper {
    // height: calc(100% - 215px);
    padding-top: 183px;
    /* min-width: 768px; */
    // overflow: auto;
    > .content {
        height: 100%;
    }
}

.content {
    flex-grow: 1;
}

.MuiPaper-root{
    &.MuiDrawer-paperAnchorDockedTop {
        border-bottom: 3px solid $black;
    }
}


// .drawer-wrapper {
//     display: flex;
//     width: 100%;
//     height: 100%;

//     .MuiDrawer-paper {
//         width: $drawer-width;
//         border-right: none;
//         /* background-color: $theme-blue; */
//     }

//     .nav-sidebar {
//         position: relative;
//         width: $drawer-width;
//         height: 100%;
//         flex-shrink: 0;
//     }
// }


// $xs-drawer-heigth: 216px;

// .xs-drawer-wrapper {
//     height: 100%;
//     overflow: auto;
//     min-height: 100%;

//     .xs-nav-sidebar {

//         display: flex;
//         width: 100%;

//         .MuiDrawer-paper {
//             width: 100%;
//             height: $xs-drawer-heigth;
//             border-right: none;
//             /* background-color: $theme-blue; */
//         }

//         .nav-sidebar {
//             position: relative;
//             width: 100%;
//             height: $xs-drawer-heigth;
//             flex-shrink: 0;
//         }

//         .layout-nav {
//             display: flex;
//             align-items: center;
//             justify-content: stretch;

//             a {
//                 flex: 1;
//             }
//         }
//     }

//     .content {
//         height: 100%;
//         margin-bottom: $xs-drawer-heigth;
//         overflow: auto;
//     }

//     &.no-drawer {
//         .content {
//             height: 100%;
//             margin-bottom: 0;
//             /* padding-bottom: 10px; */
//         }
//     }
/*
    .drawer-profile-wrapper {
        .MuiDrawer-paper {
            height: calc(100% - 216px);
            margin-top: 216px;
        }
    }
    */
// }

// @media screen and (max-width: 768px){
//     $mobile-drawer-heigth: 64px;
//     .xs-drawer-wrapper {
        
//         .content {
//             height: calc(100% - #{$mobile-drawer-heigth});
//             margin-bottom: $mobile-drawer-heigth;
//         }

//         .xs-nav-sidebar {
//             .MuiDrawer-paper {
//                 height: $mobile-drawer-heigth;
//             }

//             .nav-sidebar {
//                 height: $mobile-drawer-heigth;
//             }
//         }
//     }
// }