.my-tech-preferences {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 68px;

    h1 {
        position: relative;
        width: 100%;

        .icon-right-arrow{
            position: absolute;
            left: 17px;
            top: 0;
            font-size: 23px;
            transform: rotate(0.5turn);
        }
    }

    h3 {
        margin: 30px 0 0;
    }

    p {
        padding: 0 40px;
        text-align: center;
    }
}
