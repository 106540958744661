@import "../../../../assets/styles/color-schema.scss";

.venue-filter-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .MuiDrawer-paper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 90px;
    }
}

.filter-drawer {

    .MuiDrawer-paper {
        border-bottom: none;
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        max-height: calc(95% - 144px);
        padding: 72px 0;

        .drawer-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: fixed;
            bottom: calc(95% - 72px);
            width: 100%;
            height: 72px;
            border-top-left-radius: 23px;
            border-top-right-radius: 23px;
            border-bottom: 1px solid $theme-light-grey3;
            background-color: #ffffff;
            z-index: 1;

            [class^="icon-"] {
                position: absolute;
                top: 35px;
                left: 25px;
                font-size: 12px;
            }

            .header-line {
                width: 70px;
                height: 0px;
                border: 2px solid $theme-light-grey3;
                border-radius: 4px;
                margin-top: 14px;
            }
        }

        .filter-group {
            margin: 20px;
            /* padding-bottom: 32px; */
            border-bottom: 1px solid $theme-light-grey3;
            flex: 1 0 auto;

            &:first-of-type {
                margin-top: 40px;
            }

            &:last-of-type {
                border: none;
            }

            legend {
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.51px;
                color: $theme-blue;
                font-family: 'NoyhBold';

                p {
                    text-align: left;
                    line-height: 1.57;
                }
            }

            .MuiFormControlLabel-label {
                font-family: FuturaBook;
                font-size: 18px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.39px;
            }

            label {
                display: flex;
                justify-content: space-between;
                margin-left: 0;
                margin-top: 25px;
/*
                > span.MuiIconButton-root {
                    padding: 50px;
                }
*/
                p {
                    text-align: left;
                    font-family: FuturaBook;
                    font-size: 14px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: -0.3px;
                }

                h5 {
                    font-family: FuturaBook;
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: -0.39px;
                    margin-bottom: 3px;
                }
            }

            > div {
                margin-bottom: 32px;
            }
        }

        .action-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid $theme-light-grey3;
            position: fixed;
            bottom: 0;
            width: 100%;
            height: 72px;
            background-color: #ffffff;
            z-index: 1;

            button {
                min-width: 100px;
                margin: 10px 16px;

                &:first-of-type {
                    border: none;
                    background: none;
                }

                .MuiButton-label {
                    justify-content: center;
                }

                &.btn-gradient {
                    height: 49px;
                    width: 125px;
                    
                    .MuiButton-label{
                        font-family: 'NoyhBold';
                        font-size: 18px;
                        line-height: normal;
                    }
                }
            }
        }
    }
}