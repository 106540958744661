@import "../../../../assets/styles/color-schema.scss";

$venue-image-width: 204px;

.venue-list {
    width: 100%;
    height: calc(100% - 322px);
    overflow-y: auto;

    .venue-list-item {
        border-top: 2px solid $theme-blue;
        padding: 30px 50px;
        display: flex;
        align-items: start;

        .image-box {
            width: $venue-image-width;
            height: 160px;
            position: relative;
            background-color: #b9b9b9;
            border-radius: 18px;

            .venue-photo {
                width: $venue-image-width;
                height: 160px;
                object-fit: cover;
                border-radius: 18px;
            }

            button {
                position: absolute;
                bottom: 5px;
                left: 5px;
                width: 80px;
                height: 42px;
                margin: 0;
                padding: 0;
                border-radius: 16px;

                .MuiButton-label {
                    font-family: FuturaBook;
                    font-size: 18px;

                    [class^="icon-"] {
                        margin-left: 0;
                    }
                }
            }

        }

        .venue-description {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: calc(100% - #{$venue-image-width});

            > * {
                margin: 0 30px;
            }

            > div {
                font-size: 24px;
                margin: 5px 30px;

                > span {
                    font-family: FuturaBook;
                    font-size: 24px;
                    font-weight: 300;
                    line-height: 12px;
                }
            }

            .btn-gradient {
                height: 48px;
                width: 145px;
                min-width: 0px;
                border-radius: 24px;

                .MuiButton-label {
                    font-size: 24px !important;

                    [class^="icon-"] {
                        margin: -5px 0 0 0;
                    }
                }

                &.btn-greyed {
                    background-image: none;
                    background-color: #b9b9b9;
                }
            }
        }
    }
}