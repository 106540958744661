@import "../../../../assets/styles/color-schema.scss";

.profile-tribe {

    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon-close {
        position: absolute;
            left: 17px;
            top: 23px;
            font-size: 23px;
    }

    .icon-mumbli-logo {
        font-size: 24px;
    }

    .tribe-list-item {
        font-family: FuturaBook;
        font-size: 14px;
        font-weight: 500;  
        letter-spacing: -0.3px;
        padding: 5px 0;

        span.icon-check {
            margin: 0 15px 0 30px;
            color: $theme-orange;
        }
    }

    > p {
        margin: 15px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.3px;
        text-align: center;
    }

    button {
        width: calc(100% - 36px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        border: none;
        border-radius: 22px;
        line-height: 1;

        .MuiButton-label {
            font-size: 24px;

            span {
                font-size: 24px;
            }
        }
    }

    .payment-block {

        width: calc(100% - 40px);

        > * {
            width: 100%;
        }

        .StripeElement {
            width: 100%;
            margin: 20px 0;
        }

        .action-block {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                min-width: 100px;

                &:first-of-type {
                    margin-right: 10px;
                }

                .MuiButton-label {
                    justify-content: center;
                }
            }
        }
    }

    
}