@import "../../../../assets/styles/color-schema.scss";

.venue-mobile {
    position: absolute;
    bottom: 64px;
    /* height: 0; */
    height: calc(100% - 70px);
    width: 100%;

    .filter-drawer {
        display: flex;
        position: relative;
    }

    .venue-list {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        height: 70px;
        width: 100%;
        border-radius: 23px 23px 0 0;
        background-color: #fff;
        z-index: 999;
    }

    .slider-wrapper {
        position: absolute;
        bottom: 26px;
        left: 0;
        height: 108px;
        width: 100%;
        overflow: hidden;
        z-index: 999;

        .slick-slider {
            width: 100%;
            height: 100%;
            position: relative;

            > button.slick-arrow {
                display: none !important;
            }

            .slick-list {

                /* width: 100%; */
                height: 100%;

                .slick-track {
                    height: 100%;
                    display: flex;
                    flex-direction: row;

                    .slick-slide {
                        height: 100%;

                        > * {
                            height: 100%;
                            display: flex;
                        }
                    }

                    .venue-item {
                        display: flex !important;
                        position: relative;
                        align-items: center;
                        justify-content: flex-start;
                        width: calc(100% - 12px) !important;
                        height: 100%;
                        border-radius: 16px;
                        background-color: #fff;
                        margin: auto;
                        overflow: hidden;
                
                        .icon-bookmark {
                            position: absolute;
                            top: 13px;
                            right: 13px;
                            font-size: 13px;
                        }
                
                        .image-box {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 108px;
                            min-width: 108px;
                            height: 108px;
                            overflow: hidden;
                
                            img {
                                width: auto;
                                height: 108px;
                            }

                            .venue-status {
                                top: 8px;
                                left: 8px;
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 78px;
                                height: 24px;
                                background: #ffffff;
                                border-radius: 5px;
                                font-family: FuturaBook;
                                font-size: 12px;
                                font-weight: bold;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: 0.04px;
                                text-transform: uppercase;

                                [class^="icon-"] {
                                    font-size: 12px;
                                    margin: 0 5px;
                                    color: $theme-orange;
                                }

                                img {
                                    width: 12px;
                                    height: 12px;
                                    margin: 0 5px;
                                }
                            }
                        }
                
                        .venue-description {
                            width: calc(100% - 108px);
                            padding: 21px 8px;
/*
                            h5 {
                                font-family: FuturaBook;
                                font-size: 14px;
                                font-weight: bold;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: -0.3px;
                                white-space: nowrap;
                            }
  */              
                            div {
                                font-family: FuturaBook;
                                font-size: 14px;
                                font-weight: 300;
                                letter-spacing: -0.3px;
                                margin: 0 0 3px 0;
/*
                                &:first-of-type {
                                    font-family: FuturaBook;
                                    font-size: 14px;
                                    font-weight: bold;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: normal;
                                    letter-spacing: -0.3px;
                                    margin-bottom: 5px;
                                }
*/
                                span {
                                    font-family: FuturaBook;
                                    font-size: 12px;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: normal;
                                }

                                &.venue-parameters {
                                    span {    
                                        font-weight: bold;
                                        letter-spacing: -0.26px;
                                        text-transform: capitalize;

                                        &:last-of-type {
                                            padding-left: 2px;
                                            text-indent: -7px;

                                            &:before {
                                                content: "\00b7";
                                            }
                                        }
                                    }
                                }

                                &.venue-name {
                                    white-space: nowrap;

                                    span {
                                        font-weight: bold;
                                        font-size: 14px;
                                        letter-spacing: -0.3px;

                                        &:last-of-type {
                                            padding-left: 2px;
                                            text-indent: -7px;

                                            &:before {
                                                content: "|";
                                            }
                                        }
                                    }
                                }
                            }

                            .venu-google-link {

                                height: 24px;
                                margin: 0px;
                                padding: 0px;

                                .venue-item-btn {
                                    margin-top: 4px;
                                    padding: 3px 15px;

                                    .MuiButton-label {
                                        font-family: FuturaBook;
                                        font-size: 12px;
                                        font-weight: 500;
                                        font-stretch: normal;
                                        font-style: normal;
                                        line-height: normal;
                                        letter-spacing: -0.26px;
                                        text-align: center;
                                        color: #ffffff;
                                    }
                                }
                            }
                        }
                    } 
                } 
            }
        }

    }
}