.tech-preferences {

    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        position: relative;
        width: 100%;

        .icon-right-arrow{
            position: absolute;
            left: 17px;
            top: 0;
            font-size: 23px;
            transform: rotate(0.5turn);
        }
    }

    p {
        padding: 0 40px;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: -0.39px;
    }

    > a {
        margin-top: 40px;
    }
}
