// @import "../../../../assets/styles/color-schema.scss";
.quiz {
    padding-top: 183px;
}

// .quiz-container {
//     height: 100%;

//     .wellness-quiz-importance {

//         height: calc(100% - 75px);

//         .quiz-stepper {
//             width: 100%;
//             height: 32px;
//             background: #fff;
//             position: fixed;
//             z-index: 999;

//             .MuiMobileStepper-progress {
//                 position: absolute;
//                 top: 34px !important;
//                 width: 100%;
//                 background-color: $theme-light-grey3;

//                 .MuiLinearProgress-bar {
//                     background-color: $theme-blue;
//                 }
//             }

//             > span {
//                 position: fixed;
//                 bottom: 34px !important;

//                 &.icon-left-arrow {
//                     left: 34px;
//                 }

//                 &.icon-right-arrow {
//                     right: 34px;
//                 }
//             }

//             > button {
//                 position: fixed;
//                 bottom: 18px;
//                 right: 14px;
//                 margin: 0;
//                 min-width: 158px;
//                 width: 158px;
//                 padding: 10px;
//             }

//             > a {
//                 position: fixed;
//                 bottom: 34px !important;
//                 left: 34px;
//             }
//         }

//         .quiz-screen {
//             width: 100%;
//             height: 100%;
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: start;
//             padding-top: 48px;

//             h1 {
//                 font-size: 40px;
//                 font-weight: bold;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: 0.93;
//                 letter-spacing: -0.86px;
//                 text-align: left;
//                 margin: 0;
//             }

//             p {
//                 font-size: 14px;
//                 font-weight: 300;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: 1.57;
//                 letter-spacing: -0.5px;
//                 text-align: left;
//             }

//             > * {
//                 width: calc(100% - 80px);
//             }

//             .quiz-question {
//                 h3 {
//                     margin: 28px 0;
//                 }

//                 .answer-range-block {
//                     display: flex;
//                     justify-content: space-between;
        
//                     > span {
//                         font-family: FuturaBook;
//                         /* font-size: 34px; */
//                         color: $theme-orange;
        
//                         &:last-of-type {
//                             color: $theme-purple;
//                         }
//                     }
        
//                     button {
        
//                         &:nth-of-type(n+2) {
//                             border-color: $theme-grey3;
        
//                             &:hover {
//                                 background-color: $theme-grey3;
//                             }
//                             &.active {
//                                 background-color: $theme-grey3;
//                             }
//                         }

//                         &:nth-of-type(n+3) {
//                             border-color: $theme-purple;
        
//                             &:hover {
//                                 background-color: $theme-purple;
//                             }
//                             &.active {
//                                 background-color: $theme-purple;
//                             }
//                         }
//                     }
//                 }
//             }
//         }

//     }
// }