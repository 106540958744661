@import "../../../../assets/styles/color-schema.scss";

.venues-for-me {
    position: relative;
    height: calc(100vh - 185px);
    @media screen and (max-width: 1024px){
        height: calc(100vh - 364px);
    }
    .MuiDrawer-paper {
        top: 185px;
        height: calc(100vh - 185px);
        width: 420px;
        padding: 24px;
        @media screen and (max-width: 1024px){
            top: inherit;
            bottom: 0;
            width: 100%;
            height: auto;
            padding: 16px !important;
        }
        .MuiTypography-h4{
            @media screen and (max-width: 1024px){
                font-size: 24px !important;
            }
        }
        .card-list {
            @media screen and (max-width: 1024px){ 
                display: flex;
                width: 300px;
            }
            .card{
                display: flex;
                border-radius: 16px;
                margin-bottom: 16px;
                flex: none;
                height: 150px;
                @media screen and (max-width: 1024px){ 
                    margin-bottom: 0;
                    margin-right: 16px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                .img{
                    width: 110px;
                    flex: none;
                }
            }

        }
        
    }
    main{
        padding-left: 420px;
        width: calc(100vw - 420px);
        height: calc(100vh - 185px);
        position: relative;
        @media screen and (max-width: 1024px){ 
            padding: 0;
            width: 100%;
            height: calc(100vh - 364px);
        }
    }
    .autocomplete-dropdown-container{
        max-width: 400px;
        margin: 8px auto;
        border-radius: 16px;
        text-align: left;
        background-color: #fff;
        max-height: 200px;
        overflow-y: auto;
        z-index: 1200;
        [role="option"] {
            cursor: pointer;
            &:hover {
                background-color: $theme-light-grey;
            }
        }
    }
}

// .venues-for-me {
//     height: 100;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     h1 {
//         position: relative;
//         width: 100%;
//         font-size: 40px;
//         font-weight: normal;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: 0.93;
//         letter-spacing: -0.86px;
//         text-align: center;
//         margin: 63px 0 14px 0;

//         .icon-settings {
//             position: absolute;
//             right: 23px;
//             font-size: 23px;
//         }
//     }

//     h2{
//         position: relative;
//         width: 100%;
//         font-size: 40px;
//         font-weight: normal;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: 0.93;
//         letter-spacing: -0.86px;
//         text-align: center;
//         margin-bottom: 60px;
//         margin-left: 380px;

//         .icon-settings {
//             position: absolute;
//             right: 23px;
//             font-size: 23px;
//         }
//     }
//     .venue-block {
//         width: 100%;
//         margin-top: 70px;

//         .MuiFormGroup-root {
//             position: relative;

//             label {
//                 top: -15px;
//             }
//         }

//         .MuiFormLabel-root {
//             color: $theme-blue;
//         }

//         .MuiOutlinedInput-notchedOutline {
//             border-color: $theme-blue;
//         }


//         legend {
//             font-size: 24px;
//             font-weight: bold;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: -0.51px;

//             span {
//                 font-family: FuturaBook;
//                 font-size: 14px;
//                 font-weight: 300;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: 1.71;
//                 letter-spacing: -0.3px;
//             }
//         }

//         label {
//             top: -70px;
//             justify-content: space-between;
//             margin: 12px 0;

//             h5 {
//                 font-family: FuturaBook;
//                 font-size: 18px;
//                 font-weight: bold;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: -0.39px;
//             }

//             svg {
//                 path {
//                     font-size: 21px;
//                 }
//             }

//             .MuiFormControlLabel-label  {
//                 font-family: FuturaBook;
//                 font-size: 18px;
//             }
//         }
//     }
//     .content {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;

//         p {
//             margin: 28px 0;
//             line-height: 1.5;
//             letter-spacing: -0.3px;
//             color: $theme-dark-grey;

//             a {
//                 font-size: 18px;
//             }
//         }
//     }
//     .leaflet-map {
//         flex-shrink: 1;
    
//         .icon-placeholder {
//             color: $theme-blue;
//             font-size: 81px;
    
//             &.active {
//                 color: $theme-purple;
//             }
    
//         }
//     }
    
//     @media screen and (max-width: 768px){
//         .leaflet-map {
//             .icon-placeholder {
//                 font-size: 41px;
//             }
//         }
//     }
// }