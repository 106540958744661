@import "../../../../assets/styles/color-schema.scss";

.tech-quiz {

    display: flex;
    flex-direction: column;

    .answer-tech-block {
        display: flex;
        flex-direction: column;

        button {
            margin: 7px 0;
            border: 3px solid $theme-blue;
            border-radius: 14px;

            .MuiButton-label {
                font-family: FuturaBook;
                font-size: 24px;
                font-weight: 300;
                align-items: center;
                justify-content: start;
            }

            &:hover {
                .MuiButton-label {
                    .MuiSvgIcon-root path {
                        color: $theme-light-grey !important;
                    }
                }
            }

            &.active {

                background-color: $theme-blue;

                .MuiButton-label {
                    color: $theme-text-light-color;
                    .MuiSvgIcon-root path {
                        color: $theme-light-grey !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .tech-quiz {
        position: relative;
        padding: 0px 45px;

        display: flex;
        flex-direction: column;
        align-items: center;

        .icon-right-arrow{
            position: fixed;
            left: 17px;
            top: 20px;
            font-size: 18px;
            transform: rotate(0.5turn);
        }
    
        .answer-tech-block {
            button {
                border-width: 1px;
                border-radius: 7px;
                padding: 8px 0;

                .MuiButton-label {
                    justify-content: center;
                    font-size: 14px;
                }
            }
        }
    }
}