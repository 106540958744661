@import "~styles/color-schema.scss";

.auth-form-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid $theme-grey3;
    @media screen and (max-width: 1024px) { 
        padding: 16px !important;
        height: 72px;
    }
    &.post-auth-header{
        .icon-mumbli-logo-2 {
            @media screen and (max-width: 1024px) {
                margin: 0 auto;
            }
        }
        
    }
    .icon-mumbli-logo-2{
        font-size: 40px;
        color: $primary-main;
        @media screen and (max-width: 1024px) { 
            font-size: 32px;
        }
    }
    .MuiTypography-h2 {
        @media screen and (max-width: 1024px) { 
            font-size: 30px;
        }
    }
}

.signup-content {
    .signup-form {

        label {
            font-family: NoyhBold;
            font-size: 20px;
            position: inherit;
            top: inherit;
            transform: inherit;
            margin-bottom: .5rem;
            color: $theme-text-color;
        }
        input,
        .MuiSelect-selectMenu {
            height: 1.5rem;
            font-size: 18px;
            font-family: NoyhBold;
            color: $theme-text-color;
            line-height: 1;
        }
        .MuiSelect-selectMenu {
            min-height: inherit;
        }
        .MuiSelect-icon{
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
            width: 2rem;
            height: 2rem;
        }
        .MuiMenuItem-root{
            font-size: 18px;
            font-family: NoyhBold;
        }
        p{
            display: block;
            text-align: left;
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
            border-color: $theme-text-color;
        }
        .MuiOutlinedInput-notchedOutline {
            border-radius: .5rem !important;
        }
        .MuiTouchRipple-root{
            font-size: 16px;
        }

        .btn-root {
            .MuiButton-startIcon{
                position: absolute;
                left: 16px;
            }
        }

        .otp {
            input {
                border: 1px solid lightgrey;
                border-radius: .5rem;
                width: 52px !important;
                height: 52px;
                font-size: 18px;
                color: $theme-text-color;
                font-weight: 400;
                margin: 0 .5rem;
                &:hover {
                    border-color: rgba(0, 0, 0, 0.87);
                }
                &:focus {
                    border: 2px solid $theme-text-color;
                }
            }
        }

    }

    .pure-link {
        font-family: FuturaBook;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        text-decoration: underline;
        color: $theme-text-color;
        text-align: center;
        display: block;
    }
    .MuiFormHelperText-root{
        font-size: 16px;
        color: $theme-text-color;
        font-family: FuturaBook;
        font-weight: 400;
    }
}